<template>
  <v-runtime-template :template="homePageHtmlContent"></v-runtime-template>
</template>

<script>
import VRuntimeTemplate from 'vue3-runtime-template';

export default {
  name: 'TheHomepage',
  components: { VRuntimeTemplate },
  props: {
    homePageHtmlContent: {
      type: String,
      required: false,
      default: '',
    },
    instanceInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    subscriptionPlan: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss"></style>
