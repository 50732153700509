import { defineStore } from 'pinia';
import { ref } from 'vue';
import { fetchHomePageTemplate } from '@/app/api/home-page';

export const useHomepageStore = defineStore('homepage', () => {
  const homeHtmlContent = ref(null);
  const fetchHomepageError = ref(false);

  const getHomeHtmlContent = () => {
    fetchHomePageTemplate()
      .then(res => {
        homeHtmlContent.value = res;
      })
      .catch(() => {
        fetchHomepageError.value = true;
      });
  };

  return { homeHtmlContent, getHomeHtmlContent, fetchHomepageError };
});
