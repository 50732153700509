<template>
  <div id="home-super-container">
    <TheHomepage
      :home-page-html-content="homeHtmlContent"
      :instance-info="instance"
      :subscription-plan="subscriptionPlan"
    />
  </div>
</template>

<script>
import { onBeforeMount } from 'vue';
import { useInstanceStore } from '@/app/stores/instance';
import { storeToRefs } from 'pinia';
import { useHomepageStore } from '@/app/stores/homepage';
import TheHomepage from '@/app/components/TheHomepage.vue';
import { useTariffStore } from '@/app/stores/tariff';

export default {
  name: 'Home',
  components: { TheHomepage },
  inheritAttrs: false,
  setup() {
    const instanceStore = useInstanceStore();
    const { instance } = storeToRefs(instanceStore);

    const homepageStore = useHomepageStore();
    const { homeHtmlContent } = storeToRefs(homepageStore);

    const tariffStore = useTariffStore();
    const { subscriptionPlan } = storeToRefs(tariffStore);

    onBeforeMount(() => {
      homepageStore.getHomeHtmlContent();
    });

    return { homeHtmlContent, instance, subscriptionPlan };
  },
};
</script>
